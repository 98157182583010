const $ = require('jquery'); // requireでjQueryの読み込み
$(function() {
	const _headerHeight = $(".header-wrap").height();
	$("main").css("padding-top",_headerHeight);
	$(".header-nav-button").on("click", function() {
		if ($(this).hasClass("active")) {
			$(this).removeClass("active");
			$(".header-nav-wrap")
				.addClass("close")
				.removeClass("open");
			} else {
				$(this).addClass("active");
				$(".header-nav-wrap")
					.addClass("open")
					.removeClass("close");
		}
	});
});
